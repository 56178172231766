<template>
  <v-dialog
    v-model="dialog"
    max-width="1600"
    scrollable
    @click:outside="$emit('closeDialog')"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent"> Novo Pedido </v-card-title>

      <v-card-text class="px-5 py-6">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <div class="formsup mb-4 justify-space-between">
            <div class="forminf w-full" style="width: 100%">
              <v-autocomplete
                v-model="form.cliente_id"
                :items="clientes"
                label="Cliente"
                hide-details
                clearable
                dense
                style="max-width: 400px; width: 100%"
                :disabled="usuarioAvulso"
                :rules="[inputRequired]"
              >
              </v-autocomplete>

              <v-checkbox
                v-model="usuarioAvulso"
                label="Usuário Avulso"
                dense
              />
            </div>

            <div class="forminf" style="width: 100%">
              <v-text-field
                v-model="form.assento"
                label="Assento"
                dense
                type="number"
                style="max-width: 300px"
              />

              <div>
                <label>Data</label>
                <p>{{ form.data | dateFormat("dd/MM/yyyy") }}</p>
              </div>
            </div>
          </div>
          <v-data-table
            :ref="tableRef"
            :loading="loading"
            :headers="headers"
            :items="form.produtos"
            class="data-tables"
            :disable-pagination="true"
            hide-default-footer
          >
            <template v-slot:body.append>
              <tr :colspan="4">
                <v-btn
                  color="xbColor"
                  small
                  class="ma-2"
                  @click="addNovoProduto"
                >
                  <v-icon size="1rem">mdi-plus</v-icon>
                  Novo Produto
                </v-btn>
              </tr>
            </template>

            <template v-slot:item.produto_id="{ item }">
              <v-autocomplete
                v-model="item.produto_id"
                :items="produtos"
                hide-details
                clearable
                dense
                item-text="descricao"
                item-value="id"
                :rules="[inputRequired]"
              />
            </template>

            <template v-slot:item.valorUnit="{ item }">
              R$
              {{
                produtos.find((prod) => prod.id === item.produto_id)?.valor ||
                0 | currency
              }}
            </template>

            <template v-slot:item.qtd="{ item }">
              <div class="d-flex flex-row" style="gap: 4px">
                <v-icon dense @click="item.qtd > 1 && item.qtd--"
                  >mdi-minus-circle</v-icon
                >
                <v-text-field
                  v-model="item.qtd"
                  dense
                  hide-details
                  type="number"
                  class="centered-input"
                  style="max-width: 2rem"
                  :rules="[inputRequired, minQtde]"
                />
                <v-icon dense @click="item.qtd++">mdi-plus-circle</v-icon>
              </div>
            </template>

            <template v-slot:item.valor="{ item }">
              R$
              {{ item.valor | currency }}
            </template>

            <template v-slot:item.acoes="{ index }">
              <v-icon color="error" @click="deleteItem(index)">
                mdi-delete-outline
              </v-icon>
            </template>
          </v-data-table>
          <div class="d-flex flex-column align-end">
            <div>
              <h4>
                Valor:
                <span class="black--text"> R$ {{ somaItens | currency }}</span>
              </h4>
              <h4
                class="d-flex flex-row align-self-end align-baseline"
                style="gap: 4px"
              >
                Descontos:
                <v-text-field
                  v-model="form.desconto"
                  prefix="R$"
                  style="max-width: 80px"
                  class="text-body-2 font-weight-bold"
                  dense
                  required
                  v-currency="vCurrencyOptions"
                />
              </h4>
              <h4>
                Total:
                <span class="black--text"> R$ {{ valorTotal | currency }}</span>
              </h4>
            </div>
          </div>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions
        class="d-flex justify-space-between"
        v-if="perfil === 'ADMIN' || perfil === 'MERCADO'"
      >
        <v-btn class="xbColor--text" @click="$emit('closeDialog')">
          Fechar
        </v-btn>
        <v-btn class="xbColor--text" @click="abrirPedido"> Abrir Pedido </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { all } from "@/api/admin/usuarios";
import { formatCurrency } from "@/filters";
import { allProdutos, createPedido } from "@/api/admin/loja";
import { inputRequired, minQtde } from "@/plugins/utils";

export default {
  name: "NovoPedidoDialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        cliente_id: null,
        assento: null,
        data: new Date().toISOString(),
        produtos: [],
        desconto: "0,00",
      },
      validForm: true,
      produtos: [],
      tableRef: 0,
      usuarioAvulso: false,
      clientes: [],
      confirm: false,
      message: "",
      action: () => {},
      headers: [
        {
          value: "produto_id",
          text: "Descrição",
        },
        {
          value: "valorUnit",
          text: "Valor Unitário",
          width: "9rem",
        },
        {
          value: "qtd",
          text: "Quantidade",
          width: "7.5rem",
        },
        {
          value: "valor",
          text: "Valor",
          width: "5.5rem",
          align: "center",
        },
        {
          value: "acoes",
          text: "Ações",
          width: "5.5rem",
          align: "center",
        },
      ],
    };
  },
  computed: {
    somaItens() {
      let soma = 0;
      this.form.produtos.forEach((item) => {
        soma += Number(item.valor);
      });
      return soma;
    },
    valorTotal() {
      const valor =
        this.somaItens - Number(this.form?.desconto?.replace(",", "."));
      if (valor > 0) return valor;
      else return 0;
    },
    vCurrencyOptions() {
      return {
        precision: 2,
        distractionFree: false,
      };
    },
    ...mapState("Usuario", {
      perfil: (state) => state.role,
    }),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getClientes();
        this.getProdutos();
      }
    },
    usuarioAvulso(val) {
      if (val) this.form.cliente_id = 0;
      else this.form.cliente_id = null;
    },
    "form.produtos": {
      handler(newValue) {
        newValue.forEach((item, index) => {
          this.form.produtos[index].valor =
            (this.produtos.find((prod) => prod.id === item.produto_id)?.valor ||
              0) * Number(item.qtd);
        });
      },
      deep: true,
    },
  },
  methods: {
    cancelarAcao() {
      this.message = "";
      this.confirm = false;
      this.action = () => {};
    },
    abrirPedido() {
      if (this.$refs.form.validate()) {
        if (this.form.produtos.length > 0) {
          this.loading = true;
          const request = {
            valor: this.somaItens,
            desconto: Number(this.form.desconto?.replace(",", ".")),
            valor_total: this.valorTotal,
            cliente_id: this.form.cliente_id,
            assento: this.form.assento ? Number(this.form.assento) : null,
            vendaItens: this.form.produtos,
          };
          createPedido(request)
            .then(() => {
              this.$toast.success("Pedido criado com sucesso");
              this.$emit("closeDialog");
              this.$emit("updateTable");
              this.form = {
                assento: null,
                cliente_id: null,
                data: new Date().toISOString(),
                desconto: "0,00",
                produtos: [],
              };
              this.cancelarAcao();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$toast.error("Você precisa adicionar no mínimo um produto");
        }
      }
    },
    getClientes() {
      all().then((response) => {
        this.clientes = response.map((item) => {
          return {
            value: item.id,
            text: item.nome,
          };
        });
      });
    },
    getProdutos() {
      allProdutos().then((response) => {
        this.produtos = response;
      });
    },
    addNovoProduto() {
      this.form.produtos.push({
        produto_id: null,
        qtd: 1,
        valor: 0,
      });
    },
    deleteItem(indexDelete) {
      this.form.produtos = this.form.produtos.filter(
        (item, index) => index !== indexDelete
      );
      this.tableRef++;
    },
    formatCurrency,
    inputRequired,
    minQtde,
  },
};
</script>

<style scoped>
.formsup {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.forminf {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.centered-input {
  text-align: center !important;
}

@media (max-width: 425px) {
  .formsup {
    flex-direction: column;
    gap: 0;
  }
}

@media (max-width: 1024px) {
  .forminf {
    flex-direction: column;
    gap: 0;
  }
}
</style>
